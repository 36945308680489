import { PaymentMethodsInput, ServiceStatus } from '@app/graphql'
import { proxy } from 'valtio'

export type PaymentMethodsInputExtra = PaymentMethodsInput & {isAutoActivated: boolean, name: string}

interface ServiceType {
  serviceId?: string;
  channelId: string;
  tipsterId: string;
  status: ServiceStatus;
  autoRenewal: boolean;
  serviceName: string;
  paymentMethods: PaymentMethodsInputExtra[];
  activateFreeTrial: boolean;
  freeTrialPeriod: number;
  allowedPayments: string[];
}

const initialServiceState: ServiceType = {
  serviceId: null,
  channelId: '',
  tipsterId: '',
  status: ServiceStatus.Active,
  autoRenewal: true,
  serviceName: '',
  paymentMethods: [],
  activateFreeTrial: true,
  freeTrialPeriod: 0,
  allowedPayments: null,
}

export const serviceState = proxy<ServiceType>(initialServiceState)

export interface SubscriptionType {
  subscriptionId?: string;
  serviceId?: string;
  price: number;
  countryIds: string[];
  currency: {id: string, code: string};
  duration: number;
}

export const initialSubscriptionState: SubscriptionType = {
  subscriptionId: null,
  serviceId: null,
  duration: null,
  price: null,
  countryIds: [],
  currency: null,
}

interface SubscriptionListType {
  subs: SubscriptionType[]
}

const initialSubscriptionListState: SubscriptionListType = {
  subs: []
}

interface MiscServerStateType {
  servicedChannels: string[]
  existingFreeTrialPeriod: number
  freeTrialPeriodSubscriptionId: string
  isEdittingSubscription: boolean
  isEdittingService: boolean
}

const initialMiscServerState: MiscServerStateType = {
  servicedChannels: [],
  existingFreeTrialPeriod: null,
  freeTrialPeriodSubscriptionId: null,
  isEdittingSubscription: false,
  isEdittingService: false,
}

export const MiscServiceSubServerState = proxy<MiscServerStateType>(initialMiscServerState)
export const resetMiscServiceSubServerState = () => {
  Object.assign(MiscServiceSubServerState, initialMiscServerState)
}

export const subscriptionListState = proxy<SubscriptionListType>(initialSubscriptionListState)
export const resetSubscriptionNServiceSTorage = () => {
  Object.assign(subscriptionListState, initialSubscriptionListState)
  Object.assign(serviceState, initialServiceState)
}
