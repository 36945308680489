import { SelectElementType, Tip as FrontendTip } from '@app/types'
import {
  Competitor,
  CompetitorType,
  CountryModel,
  OutcomeEvent,
  OutcomesList,
  Team,
  TipSportEventType,
  TournamentModel,
  TypesOfContent,
  User
} from '@app/graphql'
import { Icon, IconColor, IconTypes, SportsIconsMap } from '@app/ui'
import { Misc } from '@app/helpers'

interface TransformToSelectOptionsType {
  isFlag?: boolean;
  isSport?: boolean;
  isTournament?: boolean;
  isBookies?: boolean;
  isMarkets?: boolean;
  customTitleField?: string;
  withFullData?: boolean;
}

export const transformToSelectList = (
  list: readonly unknown[] | unknown[],
  ids: Set<string>,
  options?: TransformToSelectOptionsType
): SelectElementType[] => {
  if (!list?.length) return []

  return list.map((item) => {
    if (item) {
      const fullData = options?.withFullData ? item : undefined
      const id = item.id || item.oddsType
      const title = transformTitle(item, options)
      const amount = item.amount
      let caption = item?.caption ?? null
      const popupInfo = item?.popupInfo ?? null
      let image = options?.isFlag ?
        (
          item?.code ?
            <span
              className={`fi fi-${item.code} fis`}
              style={{ width: '2.5rem', height: '2.5rem', padding: 0, margin: 0, borderRadius: '50%' }}
            /> :
            <div
              style={{ width: 40, height: 40, borderRadius: '50%', border: '1px solid var(--font-color-brand-dark)' }}
            />
        ) :
        null
      if (options?.isSport) {
        const icon: IconTypes = SportsIconsMap.get(item.id)
        image = icon ? <Icon type={icon} color={IconColor.fontBrandDark} width={24} height={24}/> : image
      }
      if (options?.isTournament) {
        caption = item?.category?.name || null
      }
      if (options?.isMarkets) {
        caption = item?.label || null
      }
      const selected = ids.has(id)

      return { id, title, amount, image, selected, fullData, caption, popupInfo }
    }
    return item
  })
}

const transformTitle = (item, options?):string => {
  const title = item.title || item.name || item[options?.customTitleField]
  return title.toLowerCase() === Misc.misc.SOCCER.toLowerCase() ? Misc.misc.FOOTBALL : title
}

export const getTitlesString = (list: readonly any[], ids: Set<string>): string => {
  return list.reduce((str, item) => {
    if (ids.has(item.id)) {
      const title = item.title || item.name

      if (str) {
        return `${str}, ${title}`
      }
      return title
    }
    return str
  }, '')
}
export const getCodeString = (list: readonly any[], ids: Set<string>): string => {
  return list.reduce((str, item) => {
    if (ids.has(item.id)) {
      const title = item.code || item.name

      if (str) {
        return `${str}, ${title}`
      }
      return title
    }
    return str
  }, '')
}

export const getFlagFromList = (list: CountryModel[], id: string): JSX.Element => {
  if (!id || !list?.length) return null

  const selectedElement = list.find(item => item?.id === id)

  if (selectedElement?.code) {
    return <span
      className={`fi fi-${selectedElement.code} fis`}
      style={{ width: '1.75rem', height: '1.75rem', borderRadius: '50%', padding: 0, margin: 0 }}
    />
  }
  return null
}

type GetFilteredElements = <T>(elements: T[], options: { searchString: string, field: string }) => T[]

export const getFilteredElementsBySearch: GetFilteredElements = (elements, options) => {
  if (options.searchString) {
    const searchRegex = new RegExp(options.searchString, 'i')

    return elements.filter(item => item[options.field]?.search?.(searchRegex) > -1)
  } else {
    return elements
  }
}

export const stringifyCompetitors = (competitors: Competitor[]) => {
  if (!competitors?.length) return ''
  let home = ''
  let away = ''

  competitors.forEach(c => {
    if (c.type === CompetitorType.Local) {
      home = c.name
    } else if (c.type === CompetitorType.Visitor) {
      away = c.name
    }
  })

  return home + ' - ' + away
}

/**
 * transform from Competitors Array to Map
 * return Map<key = competitor id, value = competitor image url>
 */
export const prepareCompetitorsMap = (competitors: Team[]) => {
  const map = new Map<string, string>()

  if (competitors?.length) {
    competitors.forEach(c => map.set(c.externalId, c.imageUrl))
  }
  return map
}

/**
 * For 2 competitors
 */
export const getCompetitorsData = (competitors: Competitor[], compMap: Map<string, string>) => {
  const home = { id: null, name: '', imageSrc: null }
  const away = { id: null, name: '', imageSrc: null }

  competitors?.forEach(c => {
    if (c.type === CompetitorType.Local) {
      home.id = c.id
      home.name = c.name
      home.imageSrc = compMap.get(c.id)
    } else if (c.type === CompetitorType.Visitor) {
      away.id = c.id
      away.name = c.name
      away.imageSrc = compMap.get(c.id)
    }
  })

  return { home, away }
}

export const parseCompetitorNames = (eventName: string) => eventName?.split(' - ') || []

interface CommonFields {
  isVip: boolean;
  tipAnalysis: string;
  tipStake: number;
  bookieId: string;
  createdAt: Date;
  customBookieName: string;
  isCommentsAllow: boolean;
  user: User;
}

export const transformTips = (tips: readonly FrontendTip[], fields: CommonFields): any[] => tips.map(t => ({
  ...t,
  anonymized: false,
  bookieId: fields.bookieId,
  createdAt: fields.createdAt,
  customBookieName: fields.customBookieName,
  event: {
    ...t.eventData,
    eventType: '',
    outcome: {
      id: t.outcomeId,
      name: t.outcomeName,
      bookmakerName: fields.customBookieName,
    },
    oddType: {
      id: t.marketId,
      name: t.marketName,
    },
    tournament: t.eventData.tournament as unknown as TournamentModel,
    __typename: 'TipSportEventType',
  } as TipSportEventType,
  id: t.tipTemporaryId,
  isCommentsAllow: fields.isCommentsAllow,
  isMultitip: true,
  odds: t.odd,
  updatedAt: fields.createdAt,
  user: fields.user,
  userId: fields.user.id,
  vip: fields.isVip,
  blocked: false,
}))

export const transformOutcomes = (outcomes: OutcomeEvent[]) => {
  const outcomeMap = new Map<number, { [key: string]: OutcomeEvent }>()
  let firstType = ''
  let secondType = ''

  outcomes.forEach(outcome => {
    if (!firstType) {
      firstType = outcome.outcomeName
    } else if (!secondType && firstType !== outcome.outcomeName) {
      secondType = outcome.outcomeName
    }

    if (outcomeMap.has(outcome.specialBetValue)) {
      outcomeMap.get(outcome.specialBetValue)[outcome.outcomeName] = outcome
    } else {
      outcomeMap.set(outcome.specialBetValue, { [outcome.outcomeName]: outcome })
    }
  })

  const outcomeArray = Array.from(outcomeMap).sort((elA, elB) => {
    if (elA[0] > elB[0]) return 1
    if (elB[0] > elA[0]) return -1
    return 0
  })

  return { firstType, secondType, outcomeArray }
}

export const contentTypeMap = new Map<string, TypesOfContent>([
  [ 'PostDetailed', TypesOfContent.Post ],
  [ 'PollDetailed', TypesOfContent.Poll ],
  [ 'MultitipDetailed', TypesOfContent.Multitip ],
  [ 'TipDetailed', TypesOfContent.Tip ],
])
/**
 * Sorts outcomes list by special bet value inside name
 * @param list array of OutcomesList
 * @returns sorted array of OutcomesList
 */
export const sortOutcomeList = (list: Partial<OutcomesList>[]) => {
  const regExp = /(-|\+)?\d+(\.\d+)?/g
  const getNumber = (outcome: Partial<OutcomesList>) => outcome?.name?.match(regExp)?.[0]

  if (!getNumber(list?.slice()?.[0])) return list
  return list?.filter(o => getNumber(o))?.sort((a, b) => Number(getNumber(a)) - Number(getNumber(b))) || []
}
