import {
  RadioButtonIcon,
  CircleIcon,
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  CircleCheckIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  AngleDownIcon,
  AlertTriangleIcon,
  ArrowLeftIcon,
  FacebookIcon,
  GoogleIcon,
  CheckmarkIcon,
  CloseIcon,
  PlusIcon,
  TicketIcon,
  EditIcon,
  VoteIcon,
  ArrowRightIcon,
  CalendarIcon,
  MenuIcon,
  NewspaperIcon,
  GearIcon,
  StarIcon,
  LetterIcon,
  BookmarksIcon,
  BellIcon,
  ShareIcon,
  LockIcon,
  LogoutIcon,
  DragIcon,
  ThinPlusIcon,
  ThinMinusIcon,
  CommentIcon,
  UpvoteIcon,
  DownvoteIcon,
  UpvoteFilledIcon,
  DownvoteFilledIcon,
  PollIcon,
  ClockIcon,
  TelegramIcon,
  WhatsAppIcon,
  TwitterIcon,
  ViberIcon,
  CopyIcon,
  PersonCircleOutlineIcon,
  InfoIcon,
  InformationOutlineIcon,
  CaretDownIcon,
  CaretUpIcon,
  AngleLeftIcon,
  AngleRightIcon,
  BackIcon,
  AttachIcon,
  PostIcon,
  EmojiIcon,
  FavouriteIcon,
  CircleCrossIcon,
  ThreeDotsIcon,
  SpinIcon,
  EventsIcon,
  BrushIcon,
  TrashBinIcon,
  PinIcon,
  AddCircleOutlineIcon,
  FootballIcon,
  TennisIcon,
  GolfIcon,
  CyclingIcon,
  ESportsIcon,
  RugbyIcon,
  CricketIcon,
  BasketballIcon,
  BaseballIcon,
  AmericanFootballIcon,
  HockeyIcon,
  VolleyballIcon,
  BrandIcon,
  WalletIcon,
  TeamIcon,
  EarthOutlineIcon,
  ShareIOSSafariIcon,
  ShareIOSSafariModernIcon,
  HomeScreenIOSSafariIcon,
  HomeScreenIOSSafariModernIcon,
  HandballIcon,
  RugbyLeagueIcon,
  BoxingIcon,
  MMAIcon,
  TableTennisIcon,
  TandC,
  InstagramIcon,
  Delete,
  Warning,
  Upload,
  AddImage,
  AddGif,
  IosInstall,
  IosShareIcon,
  RedirectIcon,
  DownloadIcon,
  DownloadArrow,
  StarLineIcon,
  ShareLineIcon,
  AddUserLineIcon,
  AngleTopIcon,
  AngledownLineIcon
} from './svg'

export enum IconSize {
    small = 24,
    medium = 28,
    large = 32,
    xLarge = 48,
    xsmall = 16,
}

// duplicates colors from "main.css"
export enum IconColor {
    fontPrimary = '#1E2023',
    fontPrimaryDarker = '#1B443C',
    fontBrandDark = '#1BAA90',
    fontBrandDarkNew = '#1B504A',
    fontBlack = '#212121',
    brand = '#68D0BD',
    brandDark = '#1BAB92',
    warning = '#EB445A',
    success = '#27C27D',
    grayLight = '#F4F5F8',
    grayDark = '#A4A4A4',
    grayDarker = '#666666',
    grayDarkest = '#4D4D4D',
    grayNavigation = '#92949C',
    grayC = '#CCCCCC',
    chosenDate = '#FBCB24',
    facebookBlue = '#4267B2',
    white = '#FFFFFF',
    tertiary = '#4854E0',
    quaternaryGolden = '#FFCA22',
}

export interface IconProps {
    size?: IconSize;
    height?: number;
    width?: number;
    type: IconTypes;
    color?: IconColor;
}

export enum IconTypes {
    redirect = 'REDIRECT',
    download = 'DOWNLOAD',
    instagram = 'INSTAGRAM',
    radioButton = 'RADIO_BUTTON',
    circle = 'CIRCLE',
    checkboxChecked = 'CHECKBOX_CHECKED',
    checkboxUnchecked = 'CHECKBOX_UNCHECKED',
    circleCheck = 'CIRCLE_CHECK',
    home = 'HOME',
    magnifyingGlass = 'MAGNIFYING_GLASS',
    angleDown = 'ANGLE_DOWN',
    alertTriangle = 'ALERT_TRIANGLE',
    arrowLeft = 'ARROW_LEFT',
    facebook = 'FACEBOOK',
    google = 'GOOGLE',
    checkmark = 'CHECKMARK',
    close = 'CLOSE',
    plus = 'PLUS',
    ticket = 'TICKET',
    edit = 'EDIT',
    vote = 'VOTE',
    arrowRight = 'ARROW_RIGHT',
    calendar = 'CALENDAR',
    menu = 'MENU',
    newspaper = 'NEWSPAPER',
    gear = 'GEAR',
    star = 'STAR',
    letter = 'LETTER',
    bookmarks = 'BOOKMARKS',
    bell = 'BELL',
    share = 'SHARE',
    lock = 'LOCK',
    logout = 'LOGOUT',
    drag = 'DRAG',
    thinPlus = 'THIN_PLUS',
    thinMinus = 'THIN_MINUS',
    comments = 'COMMENTS',
    upvote = 'UPVOTE',
    downvote = 'DOWNVOTE',
    upvoteFilled = 'UPVOTE_FILLED',
    downvoteFilled = 'DOWNVOTE_FILLED',
    poll = 'POLL',
    clock = 'CLOCK',
    telegram = 'TELEGRAM',
    whatsApp = 'WHATS_APP',
    twitter = 'TWITTER',
    viber = 'VIBER',
    copy = 'COPY',
    personCircle = 'PERSON_CIRCLE',
    info = 'INFO',
    informationOutline = 'INFORMATION_OUTLINE',
    caretDown = 'CARET_DOWN',
    caretUp = 'CARET_UP',
    angleLeft = 'ANGLE_LEFT',
    angleRight = 'ANGLE_RIGHT',
    back = 'BACK',
    attach = 'ATTACH',
    post = 'POST',
    emoji = 'EMOJI',
    favourite = 'FAVOURITE',
    circleCross = 'CIRCLE_CROSS',
    threeDots = 'THREE_DOTS',
    spin = 'SPIN',
    events = 'EVENTS',
    brush = 'BRUSH',
    trashBin = 'TRASH_BIN',
    pin = 'PIN',
    addCircleOutline = 'ADD_CIRCLE_OUTLINE',
    football = 'FOOTBALL',
    tennis = 'TENNIS',
    golf = 'GOLF',
    cycling = 'CYCLING',
    eSports = 'E_SPORTS',
    rugby = 'RUGBY',
    cricket = 'CRICKET',
    basketball = 'BASKETBALL',
    baseball = 'BASEBALL',
    americanFootball = 'AMERICAN_FOOTBALL',
    hockey = 'HOCKEY',
    volleyball = 'VOLLEYBALL',
    handball = 'HANDBALL',
    rugbyLeague = 'RUGBY_LEAGUE',
    boxing = 'BOXING',
    mma = 'MMA',
    tableTennis = 'TABLE_TENNIS',
    brand = 'BRAND',
    wallet = 'WALLET',
    team = 'TEAM',
    tAndC= 'TANDC',
    earthOutline = 'EARTH_OUTLINE',
    shareIOSSafari = 'SHARE_IOS_SAFARI',
    shareIOSSafariModern = 'SHARE_IOS_SAFARI_MODERN',
    homeScreenIOSSafari = 'HOME_SCREEN_IOS_SAFARI',
    homeScreenIOSSafariModern = 'HOME_SCREEN_IOS_SAFARI_MODERN',
    delete = 'DELETE',
    warning = 'WARNING',
    upload = 'UPLOAD',
    addImage = 'ADDIMAGE',
    addGif = 'ADDGIF',
    IosInstall = 'IOSINSTALL',
    IosShare = 'IOSSHARE',
    DownloadArrow = 'DOWNLOADARROW',
    starLineIcon = 'STARLINEICON',
    shareLineIcon = 'SHARELINEICON',
    addUserLineIcon = 'ADDUSERLINEICON',
    angleTopIcon = 'ANGLETOPICON',
    angledownLineIcon = 'ANGLEDOWNLINEICON',
}

export const SvgIcons = {
  [IconTypes.radioButton]: RadioButtonIcon,
  [IconTypes.redirect]: RedirectIcon,
  [IconTypes.download]: DownloadIcon,
  [IconTypes.circle]: CircleIcon,
  [IconTypes.checkboxChecked]: CheckboxCheckedIcon,
  [IconTypes.checkboxUnchecked]: CheckboxUncheckedIcon,
  [IconTypes.circleCheck]: CircleCheckIcon,
  [IconTypes.home]: HomeIcon,
  [IconTypes.magnifyingGlass]: MagnifyingGlassIcon,
  [IconTypes.angleDown]: AngleDownIcon,
  [IconTypes.alertTriangle]: AlertTriangleIcon,
  [IconTypes.arrowLeft]: ArrowLeftIcon,
  [IconTypes.facebook]: FacebookIcon,
  [IconTypes.google]: GoogleIcon,
  [IconTypes.checkmark]: CheckmarkIcon,
  [IconTypes.close]: CloseIcon,
  [IconTypes.plus]: PlusIcon,
  [IconTypes.ticket]: TicketIcon,
  [IconTypes.edit]: EditIcon,
  [IconTypes.vote]: VoteIcon,
  [IconTypes.arrowRight]: ArrowRightIcon,
  [IconTypes.calendar]: CalendarIcon,
  [IconTypes.menu]: MenuIcon,
  [IconTypes.newspaper]: NewspaperIcon,
  [IconTypes.gear]: GearIcon,
  [IconTypes.star]: StarIcon,
  [IconTypes.letter]: LetterIcon,
  [IconTypes.bookmarks]: BookmarksIcon,
  [IconTypes.bell]: BellIcon,
  [IconTypes.share]: ShareIcon,
  [IconTypes.lock]: LockIcon,
  [IconTypes.logout]: LogoutIcon,
  [IconTypes.drag]: DragIcon,
  [IconTypes.thinPlus]: ThinPlusIcon,
  [IconTypes.thinMinus]: ThinMinusIcon,
  [IconTypes.comments]: CommentIcon,
  [IconTypes.upvote]: UpvoteIcon,
  [IconTypes.downvote]: DownvoteIcon,
  [IconTypes.upvoteFilled]: UpvoteFilledIcon,
  [IconTypes.downvoteFilled]: DownvoteFilledIcon,
  [IconTypes.poll]: PollIcon,
  [IconTypes.clock]: ClockIcon,
  [IconTypes.telegram]: TelegramIcon,
  [IconTypes.whatsApp]: WhatsAppIcon,
  [IconTypes.twitter]: TwitterIcon,
  [IconTypes.viber]: ViberIcon,
  [IconTypes.copy]: CopyIcon,
  [IconTypes.personCircle]: PersonCircleOutlineIcon,
  [IconTypes.info]: InfoIcon,
  [IconTypes.informationOutline]: InformationOutlineIcon,
  [IconTypes.caretDown]: CaretDownIcon,
  [IconTypes.caretUp]: CaretUpIcon,
  [IconTypes.angleLeft]: AngleLeftIcon,
  [IconTypes.angleRight]: AngleRightIcon,
  [IconTypes.back]: BackIcon,
  [IconTypes.attach]: AttachIcon,
  [IconTypes.post]: PostIcon,
  [IconTypes.emoji]: EmojiIcon,
  [IconTypes.favourite]: FavouriteIcon,
  [IconTypes.circleCross]: CircleCrossIcon,
  [IconTypes.threeDots]: ThreeDotsIcon,
  [IconTypes.spin]: SpinIcon,
  [IconTypes.events]: EventsIcon,
  [IconTypes.brush]: BrushIcon,
  [IconTypes.trashBin]: TrashBinIcon,
  [IconTypes.pin]: PinIcon,
  [IconTypes.addCircleOutline]: AddCircleOutlineIcon,
  [IconTypes.football]: FootballIcon,
  [IconTypes.tennis]: TennisIcon,
  [IconTypes.golf]: GolfIcon,
  [IconTypes.cycling]: CyclingIcon,
  [IconTypes.eSports]: ESportsIcon,
  [IconTypes.rugby]: RugbyIcon,
  [IconTypes.cricket]: CricketIcon,
  [IconTypes.basketball]: BasketballIcon,
  [IconTypes.baseball]: BaseballIcon,
  [IconTypes.americanFootball]: AmericanFootballIcon,
  [IconTypes.hockey]: HockeyIcon,
  [IconTypes.volleyball]: VolleyballIcon,
  [IconTypes.handball]: HandballIcon,
  [IconTypes.rugbyLeague]: RugbyLeagueIcon,
  [IconTypes.boxing]: BoxingIcon,
  [IconTypes.mma]: MMAIcon,
  [IconTypes.tableTennis]: TableTennisIcon,
  [IconTypes.brand]: BrandIcon,
  [IconTypes.wallet]: WalletIcon,
  [IconTypes.team]: TeamIcon,
  [IconTypes.earthOutline]: EarthOutlineIcon,
  [IconTypes.shareIOSSafari]: ShareIOSSafariIcon,
  [IconTypes.shareIOSSafariModern]: ShareIOSSafariModernIcon,
  [IconTypes.homeScreenIOSSafari]: HomeScreenIOSSafariIcon,
  [IconTypes.homeScreenIOSSafariModern]: HomeScreenIOSSafariModernIcon,
  [IconTypes.tAndC]: TandC,
  [IconTypes.instagram]: InstagramIcon,
  [IconTypes.delete]: Delete,
  [IconTypes.warning]: Warning,
  [IconTypes.upload]: Upload,
  [IconTypes.addImage]: AddImage,
  [IconTypes.addGif]: AddGif,
  [IconTypes.IosShare]: IosShareIcon,
  [IconTypes.IosInstall]: IosInstall,
  [IconTypes.DownloadArrow]: DownloadArrow,
  [IconTypes.starLineIcon]: StarLineIcon,
  [IconTypes.shareLineIcon]: ShareLineIcon,
  [IconTypes.addUserLineIcon]: AddUserLineIcon,
  [IconTypes.angleTopIcon]: AngleTopIcon,
  [IconTypes.angledownLineIcon]: AngledownLineIcon,
}

export interface SvgIconProps {
    width?: number;
    height?: number;
    color: IconColor;
}

export const SportIcon = [
  { id: 1, name: 'soccer', icon: '⚽' },
  { id: 2, name: 'basketball', icon:'🏀' },
  { id: 3, name: 'tennis', icon:'🎾' },
  { id: 4, name: 'hockey', icon: '🏑' },
  { id: 5, name: 'handball', icon:'🤾‍♂️' },
  { id: 6, name: 'volleyball', icon:'🏐' },
  { id: 7, name: 'football', icon:'⚽' },
  { id: 8, name: 'baseball', icon:'⚾' },
  { id: 9, name: 'cricket', icon: '🏏' },
  { id: 10, name: 'rugby', icon: '🏉' },
  { id: 11, name: 'rugby league', icon:'🏉' },
  { id: 12, name: 'boxing', icon: '🥊' },
  { id: 13, name: 'esports', icon:'🏆' },
  { id: 14, name: 'MMA', icon:'' },
  { id: 15, name: 'table tennis', icon:'🏓' },
  { id: 15, name: 'american football', icon:'🏈' }
]