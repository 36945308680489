import { TipResult, TypesOfContent } from '@app/graphql'
import { ContentUserData, UpvoteStatus, AllUserRoles } from '@app/types'
import { FC } from 'react'
import styled from 'styled-components'
import FeedPostActions from './FeedPostActions'
import FeedPostHeader from './FeedPostHeader'

interface FeedPostContainerProps {
  id: string;
  type?: TypesOfContent;
  className?: string;
  user: ContentUserData;
  createdAt: Date;
  updatedAt?: Date;
  isPrivate?: boolean;
  noActions?: boolean;
  upvotes: number;
  downvotes: number;
  upvoteStatus?: UpvoteStatus;
  commentsAllowed: boolean;
  commentsCount?: number;
  children?: React.ReactNode;
  bookieName?: string;
  status?: TipResult;
  onDelete?: () => void;
  editMode?:() => void;
  pinContentAccess?:AllUserRoles[]
  onUpdateResultMode?:(payload:string) => any;
  isPostTip?: boolean;
}

const FeedPostContainer: FC<FeedPostContainerProps> = ({
  id,
  type = TypesOfContent.Post,
  user,
  createdAt,
  updatedAt,
  isPrivate,
  children,
  className,
  noActions,
  upvotes,
  downvotes,
  upvoteStatus,
  commentsAllowed = true,
  commentsCount,
  bookieName,
  status,
  editMode,
  onDelete,
  pinContentAccess,
  onUpdateResultMode,
  isPostTip
}) => {
  return (
    <Container className={className} type={type}>
      <Content>
        <FeedPostHeader
          id={id}
          user={user}
          createdAt={createdAt}
          updatedAt={updatedAt}
          type={type}
          isPrivate={isPrivate}
          noActions={noActions}
          bookieName={bookieName}
          status={status}
          editMode={editMode}
          onDelete={onDelete}
          pinContentAccess={pinContentAccess}
          onUpdateResultMode={onUpdateResultMode}
          isPostTip={isPostTip}
        />
        {children}
        { !noActions &&
          <FeedPostActions
            id={id}
            upvotes={upvotes}
            downvotes={downvotes}
            status={upvoteStatus}
            contentType={type}
            commentsAllowed={commentsAllowed}
            commentsCount={commentsCount}
          />
        }
      </Content>
    </Container>
  )
}

const Container = styled.div<{ type: TypesOfContent }>`
  -webkit-transform: translate3d(0,0,0);
  position: relative;
  background: transparent;
  display: flex;
  flex-direction: column;
  margin: ${props => props.type === TypesOfContent.Tip ? '0' : '0.5rem'} 0 0.5rem;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.09));
  ${props => props.type === TypesOfContent.Poll && 'border-top: 3px solid var(--color-tertiary);'}
  ${props => props.type === TypesOfContent.Post && 'border-top: 3px solid var(--color-quaternary);'}
`

const Content = styled.div`
  background: white;
  padding: 0.75rem 0.75rem 1rem;
`

export default FeedPostContainer
