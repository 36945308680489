import { lazy } from 'react'

export * from './Screen'
export * from './Profile'
export * from './Feed'
export * from './CreateSubscription'
export const AppMenu =  lazy(() => import('./AppMenu'))
export const CalendarGrid =  lazy(() => import('./CalendarGrid'))
export const CalendarSlider =  lazy(() => import('./CalendarSlider'))
export const DraggableInputList =  lazy(() => import('./DraggableInputList'))
export const EventTipsModal =  lazy(() => import('./EventTipsModal'))
export const FiltersSlider =  lazy(() => import('./FiltersSlider'))
export const GiphySearchGrid =  lazy(() => import('./GiphySearchGrid'))
export const ImageContent =  lazy(() => import('./ImageContent'))
export const InstallIOSPopup =  lazy(() => import('./InstallIOSPopup'))
export const ListWithSearch =  lazy(() => import('./ListWithSearch'))
export const ListWithSearchScreen =  lazy(() => import('./ListWithSearchScreen'))
export const ListWithOutSearch =  lazy(() => import('./ListWithOutSearch'))
export const MessageInput =  lazy(() => import('./MessageInput'))
export const MutedPopup =  lazy(() => import('./MutedPopup'))
export const ProtectedRoute =  lazy(() => import('./ProtectedRoute'))
export const ShareSocial =  lazy(() => import('./ShareSocial'))
export const MasterAuth = lazy(() => import('./UserEntry'))
export const Dropdown = lazy(() => import('./Dropdown'))
export const SendMessageBox =  lazy(() => import('./SendMessageBox'))
