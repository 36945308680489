import { lazy } from 'react'

export const TeamIcon =  lazy(() => import('./TeamIcon'))
export const ShareIOSSafariIcon =  lazy(() => import('./ShareIOSSafariIcon'))
export const ShareIOSSafariModernIcon =  lazy(() => import('./ShareIOSSafariModernIcon'))
export const HomeScreenIOSSafariIcon =  lazy(() => import('./HomeScreenIOSSafariIcon'))
export const HomeScreenIOSSafariModernIcon =  lazy(() => import('./HomeScreenIOSSafariModernIcon'))
export const TrashBinIcon =  lazy(() => import('./TrashBinIcon'))
export const PinIcon =  lazy(() => import('./PinIcon'))
export const AddCircleOutlineIcon =  lazy(() => import('./AddCircleOutlineIcon'))
export const BrandIcon =  lazy(() => import('./BrandIcon'))
export const WalletIcon =  lazy(() => import('./WalletIcon'))
export const CircleCrossIcon =  lazy(() => import('./CircleCrossIcon'))
export const ThreeDotsIcon =  lazy(() => import('./ThreeDotsIcon'))
export const SpinIcon =  lazy(() => import('./SpinIcon'))
export const EventsIcon =  lazy(() => import('./EventsIcon'))
export const BrushIcon =  lazy(() => import('./BrushIcon'))
export const BackIcon =  lazy(() => import('./BackIcon'))
export const AttachIcon =  lazy(() => import('./AttachIcon'))
export const PostIcon =  lazy(() => import('./PostIcon'))
export const EmojiIcon =  lazy(() => import('./EmojiIcon'))
export const FavouriteIcon =  lazy(() => import('./FavouriteIcon'))
export const InformationOutlineIcon =  lazy(() => import('./InformationOutlineIcon'))
export const CaretDownIcon =  lazy(() => import('./CaretDownIcon'))
export const CaretUpIcon =  lazy(() => import('./CaretUpIcon'))
export const AngleLeftIcon =  lazy(() => import('./AngleLeftIcon'))
export const AngleRightIcon =  lazy(() => import('./AngleRightIcon'))
export const TwitterIcon =  lazy(() => import('./TwitterIcon'))
export const ViberIcon =  lazy(() => import('./ViberIcon'))
export const CopyIcon =  lazy(() => import('./CopyIcon'))
export const PersonCircleOutlineIcon =  lazy(() => import('./PersonCircleOutlineIcon'))
export const InfoIcon =  lazy(() => import('./InfoIcon'))
export const CommentIcon =  lazy(() => import('./CommentIcon'))
export const PollIcon =  lazy(() => import('./PollIcon'))
export const ClockIcon =  lazy(() => import('./ClockIcon'))
export const TelegramIcon =  lazy(() => import('./TelegramIcon'))
export const WhatsAppIcon =  lazy(() => import('./WhatsAppIcon'))
export const ThinMinusIcon =  lazy(() => import('./ThinMinusIcon'))
export const UpvoteIcon =  lazy(() => import('./UpvoteIcon'))
export const DownvoteIcon =  lazy(() => import('./DownvoteIcon'))
export const UpvoteFilledIcon =  lazy(() => import('./UpvoteFilledIcon'))
export const DownvoteFilledIcon =  lazy(() => import('./DownvoteFilledIcon'))
export const ShareIcon =  lazy(() => import('./ShareIcon'))
export const LockIcon =  lazy(() => import('./LockIcon'))
export const LogoutIcon =  lazy(() => import('./LogoutIcon'))
export const DragIcon =  lazy(() => import('./DragIcon'))
export const ThinPlusIcon =  lazy(() => import('./ThinPlusIcon'))
export const CalendarIcon =  lazy(() => import('./CalendarIcon'))
export const MenuIcon =  lazy(() => import('./MenuIcon'))
export const NewspaperIcon =  lazy(() => import('./NewspaperIcon'))
export const GearIcon =  lazy(() => import('./GearIcon'))
export const StarIcon =  lazy(() => import('./StarIcon'))
export const LetterIcon =  lazy(() => import('./LetterIcon'))
export const BookmarksIcon =  lazy(() => import('./BookmarksIcon'))
export const BellIcon =  lazy(() => import('./BellIcon'))
export const GoogleIcon =  lazy(() => import('./GoogleIcon'))
export const CheckmarkIcon =  lazy(() => import('./CheckmarkIcon'))
export const CloseIcon =  lazy(() => import('./CloseIcon'))
export const PlusIcon =  lazy(() => import('./PlusIcon'))
export const TicketIcon =  lazy(() => import('./TicketIcon'))
export const EditIcon =  lazy(() => import('./EditIcon'))
export const VoteIcon =  lazy(() => import('./VoteIcon'))
export const ArrowRightIcon =  lazy(() => import('./ArrowRightIcon'))
export const CheckboxUncheckedIcon =  lazy(() => import('./CheckboxUncheckedIcon'))
export const CircleCheckIcon =  lazy(() => import('./CircleCheckIcon'))
export const HomeIcon =  lazy(() => import('./HomeIcon'))
export const MagnifyingGlassIcon =  lazy(() => import('./MagnifyingGlassIcon'))
export const AngleDownIcon =  lazy(() => import('./AngleDownIcon'))
export const AlertTriangleIcon =  lazy(() => import('./AlertTriangleIcon'))
export const ArrowLeftIcon =  lazy(() => import('./ArrowLeftIcon'))
export const FacebookIcon =  lazy(() => import('./FacebookIcon'))
export const RadioButtonIcon =  lazy(() => import('./RadioButtonIcon'))
export const CircleIcon =  lazy(() => import('./CircleIcon'))
export const CheckboxCheckedIcon =  lazy(() => import('./CheckboxCheckedIcon'))
export const InstagramIcon =  lazy(() => import('./InstagramIcon'))
export const DownloadIcon =  lazy(() => import('./DownloadIcon'))
export const RedirectIcon =  lazy(() => import('./RedirectIcon'))

export { EarthOutlineIcon } from './EarthIcon'
// SPORT ICONS
export const FootballIcon =  lazy(() => import('./FootballIcon'))
export const TennisIcon =  lazy(() => import('./TennisIcon'))
export const GolfIcon =  lazy(() => import('./GolfIcon'))
export const CyclingIcon =  lazy(() => import('./CyclingIcon'))
export const ESportsIcon =  lazy(() => import('./ESportsIcon'))
export const RugbyIcon =  lazy(() => import('./RugbyIcon'))
export const CricketIcon =  lazy(() => import('./CricketIcon'))
export const BasketballIcon =  lazy(() => import('./BasketballIcon'))
export const BaseballIcon =  lazy(() => import('./BaseballIcon'))
export const AmericanFootballIcon =  lazy(() => import('./AmericanFootballIcon'))
export const HockeyIcon =  lazy(() => import('./HockeyIcon'))
export const VolleyballIcon =  lazy(() => import('./VolleyballIcon'))
export const HandballIcon =  lazy(() => import('./HandballIcon'))
export const RugbyLeagueIcon =  lazy(() => import('./RugbyLeagueIcon'))
export const BoxingIcon =  lazy(() => import('./BoxingIcon'))
export const MMAIcon =  lazy(() => import('./MMAIcon'))
export const TableTennisIcon =  lazy(() => import('./TableTennisIcon'))
export const TandC =  lazy(() => import('./TermsAndConditions'))
export const Delete =  lazy(() => import('./DeleteIcon'))
export const Warning =  lazy(() => import('./WalletIcon'))
export const Upload =  lazy(() => import('./UploadIcon'))
export const AddImage =  lazy(() => import('./AddImageIcon'))
export const AddGif =  lazy(() => import('./AddGifIcon'))
export const IosInstall =  lazy(() => import('./IosInstallIcon'))
export const IosShareIcon =  lazy(() => import('./IosShareIcon'))
export const DownloadArrow =  lazy(() => import('./DownloadArrow'))
export const StarLineIcon =  lazy(() => import('./StarLineIcon'))
export const ShareLineIcon =  lazy(() => import('./ShareLineIcon'))
export const AddUserLineIcon =  lazy(() => import('./AddUserLineIcon'))
export const AngleTopIcon =  lazy(() => import('./AngleTopIcon'))
export const AngledownLineIcon =  lazy(() => import('./AngledownLineIcon'))
