import { UserGender } from '@app/graphql'

export enum ContactFormReceiversEnum {
  tipster = 'tipster',
  appSupport = 'appSupport',
}

export interface UserDataProfile {
  avatarUrl: string;
  profileBackgroundUrl: string;
  name: string;
  surname: string;
  birthday: Date;
  countryId: string;
  bio: string;
  username: string;
  email: string;
  phone: string;
  gender: UserGender;
}
