import { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button } from '@app/ui'
import { ButtonSize, ButtonType } from '@app/types'
import i18n from '@app/libraries/i18n'

interface ButtonsBlockProps {
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonId?: string;
  confirmDisabled?: boolean;
  confirmType?: ButtonType;
  cancelType?: ButtonType;
  className?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ButtonsBlock: FC<ButtonsBlockProps> = ({
  cancelButtonText = i18n.t('common.cancel'),
  cancelType = ButtonType.normal,
  confirmButtonText = i18n.t('common.confirm'),
  confirmButtonId,
  confirmDisabled,
  confirmType = ButtonType.primary,
  className,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation()

  return (
    <StyledButtonsContainer className={className}>
      <StyledButtonWrapper>
        <Button
          size={ButtonSize.normal}
          type={cancelType}
          fluid
          onClick={onClose}
        >
          {t(cancelButtonText)}
        </Button>
      </StyledButtonWrapper>
      <StyledButtonWrapper>
        <Button
          id={confirmButtonId}
          size={ButtonSize.normal}
          type={confirmType}
          fluid
          disabled={confirmDisabled}
          onClick={onConfirm}
        >
          {t(confirmButtonText)}
        </Button>
      </StyledButtonWrapper>
    </StyledButtonsContainer>
  )
}

const StyledButtonsContainer = styled.div`
  width: 100%;
  margin-top: .5rem;
  display: flex;
  justify-content: space-between;
`

const StyledButtonWrapper = styled.div`
  width: calc(50% - 0.5rem);

  button {
    font-weight: bold;
  }
`

export default ButtonsBlock
