import { proxy } from 'valtio'

import { CurrentUserStateType } from '@app/types'
import { UserOnboardingStages, UserRoles } from '@app/graphql'

const initialState: CurrentUserStateType = {
  hasPendingSubscription: false,
  initiateOnboarding: false,
  avatarUrl: '',
  authBySocial: false,
  bio: '',
  birthday: null,
  confirmedConditions: false,
  countryId: '',
  id: null,
  incognitoMode: false,
  name: '',
  phone: '',
  profileBackgroundUrl: '',
  surname: '',
  userEmail: '',
  userType: null,
  username: '',
  gender: null,
  banned: false,
  muted: false,
  notedAboutMute: false,
  permAddPost: false,
  permEditPost: false,
  onboardingStage: null,
  ownedChannels: [],
  auth_type: null,
  get guestMode() {
    return this.incognitoMode
  },
  get isTipster() {
    return this.userType === UserRoles.Tipster
  },
  get hasOnboarded() {
    return this.onboardingStage === UserOnboardingStages.Onboarded
  }}

export const CurrentUserState = proxy<CurrentUserStateType>(initialState)

export const resetCurrentUserState = () => {
  Object.keys(initialState).forEach(key => {
    CurrentUserState[key] = initialState[key]
  })
}

export const activateGuestMode = () => {
  CurrentUserState.confirmedConditions = true
  CurrentUserState.username = 'incognito.user.displayName'
  CurrentUserState.incognitoMode = true
  CurrentUserState.userType = (
    localStorage.getItem('onboardingFlow') === UserRoles.Tipster?
      UserRoles.Tipster: UserRoles.User
  )
  CurrentUserState.onboardingStage = UserOnboardingStages.Onboarded
}
