import React, { useEffect } from 'react'
import styled from 'styled-components'

import { Loader } from '@app/ui'
import NavigationMenu from './NavigationMenu'
import { Ads } from '@app/graphql'

interface ScreenProps {
  title: string
  className?: string
  loading?: boolean
  withNavigation?: boolean
  noPadding?: boolean
  noFixedHeight?: boolean;
  adsData?: Partial<Ads>;
  children?: React.ReactNode;
}

const Screen: React.FC<ScreenProps> = ({
  className,
  children,
  title,
  loading,
  withNavigation,
  noPadding,
  adsData,
  noFixedHeight,
}) => {

  const classes = [
    'Screen',
    className
  ].join(' ')

  useEffect(() => {
    document.title = 'Votebetting' + (title ? ' - ' + title : '')
  }, [ title ])

  return (
    <ScreenStyled
      className={classes}
      $withNavigation={withNavigation}
      $withAds={!!adsData}
      $noPadding={noPadding}
      $noFixedHeight={noFixedHeight}
    >
      {
        loading ?
          <Loader /> :
          children
      }
      { withNavigation && !loading && <NavigationMenu adsData={adsData}/> }
    </ScreenStyled>
  )
}

const ScreenStyled = styled.div<{
  $withNavigation: boolean;
  $withAds: boolean;
  $noPadding: boolean;
  $noFixedHeight: boolean
}>`
  display: flex;
  flex-direction: column;
  padding: ${props => props.$noPadding ? '' : '10px'};
  max-width: 600px;
  margin: auto;
  width: 100%;
  ${props => !props.$noFixedHeight && 'height: 100vh; height: var(--app-height);'}
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  ${props => props.$withNavigation ? 'padding-bottom: 56px' : ''};
  ${props => props.$withAds ? 'padding-bottom: 62px' : ''};
  ${props => props.$withNavigation && props.$withAds ? 'padding-bottom: 108px' : ''}
`

export default Screen
